const defaultTheme = {
  dark: true,
  color: {
    primary: '#4290F7',
    darkPrimary: '#1E3755',
    primaryHover: '#0035AB',
    success: '#237804',
    successHover: '#52C41A',
    danger: '#a8071a',
    dangerHover: '#FF4D4F',
    light: '#FFFFFF',
    dark: '#111111',
    grey: '#999999',
    greyHover: '#D9D9D9',
    man: '#10239e',
    woman: '#9e1068',
    warning: 'yellow',
    warningHover: '#FAAD14',
    info: '#1890FF',
    infoHover: '#1890ff',
  },
}

export default defaultTheme
