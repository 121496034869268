import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Card, Breadcrumb, Button, Input, Form, Col } from 'antd'
import { useFormik } from 'formik'

import { useStaff } from '../../hooks'

const layout = {
  labelCol: { span: 3 },
}

export const StaffForm = () => {
  let history = useHistory()
  let { id } = useParams()
  const { loading, setStaff, staffData } = useStaff(id)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: staffData?.id || '',
      username: staffData?.username || '',
      firstname: staffData?.firstname || '',
      lastname: staffData?.lastname || '',
      phoneNumber: staffData?.phoneNumber || '',
      password: staffData?.password || '',
      password_again: staffData?.password || '',
    },
    validate: (values) => {
      let errors = {}

      if (values?.username === '' || !values?.username) {
        errors.username = 'กรุณาใส่ username'
      }

      if (values?.id === '' && values?.password === '') {
        errors.password = 'กรุณาใส่รหัสผ่าน'
      }

      if (values?.password !== values?.password_again) {
        errors.password_again = 'กรุณาใส่รหัสผ่านให้ตรงกัน'
      }

      return errors
    },
    onSubmit: async (values, action) => {
      const res = await setStaff(values)

      if (res.status === 'success') {
        history.push('/staffs')
      }
    },
  })
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
  } = formik

  return (
    <div>
      <Breadcrumb style={{ marginBottom: 16 }}>
        <Breadcrumb.Item>
          <Link to='/staffs'>เจ้าหน้าที่</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {id ? 'แก้ไขเจ้าหน้าที่' : 'เพิ่มเจ้าหน้าที่'}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card title='รายละเอียดผู้ใช้'>
        <Col span={16}>
          <Form layout='horizontal' {...layout} onFinish={handleSubmit}>
            <Form.Item label='ชื่อ-นามสกุล' style={{ marginBottom: 0 }}>
              <Form.Item
                style={{ display: 'inline-block', width: 'calc(50% - 5px)' }}
              >
                <Input
                  name='firstname'
                  placeholder='ชื่อ'
                  value={values.firstname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Item>
              <Form.Item
                style={{
                  display: 'inline-block',
                  width: 'calc(50% - 5px)',
                  marginLeft: '10px',
                }}
              >
                <Input
                  name='lastname'
                  placeholder='นามสกุล'
                  value={values.lastname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Item>
            </Form.Item>

            <Form.Item label='เบอร์โทรศัพท์'>
              <Input
                name='phoneNumber'
                placeholder='เบอร์โทรศัพท์มือถือ'
                value={values.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>

            <Form.Item
              label='Username'
              help={touched.username && errors.username}
              validateStatus={
                touched.username && errors.username ? 'error' : undefined
              }
            >
              <Input
                disabled={values?.id !== ''}
                name='username'
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>

            <Form.Item
              label='รหัสผ่าน'
              help={touched.password && errors.password}
              validateStatus={
                touched.password && errors.password ? 'error' : undefined
              }
            >
              <Input.Password
                name='password'
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>

            <Form.Item
              label='ยืนยันรหัสผ่าน'
              help={touched.password_again && errors.password_again}
              validateStatus={
                touched.password_again && errors.password_again
                  ? 'error'
                  : undefined
              }
            >
              <Input.Password
                name='password_again'
                value={values.password_again}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
            <Form.Item style={{ float: 'right' }}>
              <Button
                htmlType='button'
                onClick={() => history.push('/staffs')}
                loading={loading}
              >
                ยกเลิก
              </Button>
              <Button
                type='primary'
                htmlType='submit'
                style={{ marginLeft: 10 }}
                loading={loading}
              >
                บันทึก
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Card>
    </div>
  )
}

export default StaffForm
