import React from 'react'
import { ContextProvider } from './context'

import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { useAuthState } from 'react-firebase-hooks/auth'
import firebase from './config/firebase'

import theme from './theme'

import MainLayout from './Layout/MainLayout'
import FullScreenLoading from './Components/FullScreenLoading'

import LoginScreen from './Screens/Auth/LoginScreen'
import HomeScreen from './Screens/HomeScreen'
import StaffScreen from './Screens/StaffScreen'
import StaffForm from './Screens/StaffScreen/StaffForm'
import ChatScreen from './Screens/ChatScreen'
import GroupScreen from './Screens/MMS/GroupScreen'
import RegimenScreen from './Screens/MMS/RegimenScreen'
import PatientScreen from './Screens/PatientScreen'
import PatientNoti from './Screens/PatientScreen/PatientNoti'
import DocumentScreen from './Screens/DocumentScreen'

function App() {
  const [user, loading] = useAuthState(firebase.auth())

  if (loading && !user) {
    return <FullScreenLoading />
  }

  return (
    <ContextProvider>
      <ThemeProvider theme={theme}>
        {user ? (
          <BrowserRouter>
            <MainLayout>
              <Switch>
                <Route path='/' exact component={HomeScreen} />

                <Route
                  path='/patients/noti/:id'
                  exact
                  component={PatientNoti}
                />

                <Route path='/patients' exact component={PatientScreen} />

                <Route path='/groups' exact component={GroupScreen} />

                <Route path='/documents' exact component={DocumentScreen} />

                <Route path='/regimens' exact component={RegimenScreen} />

                <Route path='/staffs/add' exact component={StaffForm} />
                <Route path='/staffs/:id' exact component={StaffForm} />
                <Route path='/staffs' exact component={StaffScreen} />

                <Route path='/chat' exact component={ChatScreen} />

                <Route path='/staff' exact component={StaffScreen} />

                <Redirect to='/' />
              </Switch>
            </MainLayout>
          </BrowserRouter>
        ) : (
          <BrowserRouter>
            <Switch>
              <Route path='/login' exact component={LoginScreen} />

              <Redirect to='/login' />
            </Switch>
          </BrowserRouter>
        )}
      </ThemeProvider>
    </ContextProvider>
  )
}

export default App
