import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Card, Table, Breadcrumb, Button, Input, Switch } from 'antd'
import { PlusOutlined, EditOutlined } from '@ant-design/icons'
import { useStaff } from '../../hooks'

export const Staff = () => {
  let history = useHistory()

  const {
    loading,
    staffList,
    pagination,
    handleTableChange,
    changeStatus,
    handelCustomSearch,
  } = useStaff()

  const columns = [
    // {
    //   title: 'No.',
    //   dataIndex: 'no.',
    //   width: 50,
    //   key: 'no.',
    //   align: 'center',
    //   render: (data, row, index) => {
    //     return pagination.page * pagination.page_total + index + 1
    //   },
    // },

    {
      title: 'Username',
      dataIndex: 'username',
      align: 'left',
      key: 'username',
      width: 300,
    },
    {
      title: 'ชื่อ',
      dataIndex: 'firstname',
      align: 'left',
      key: 'firstname',
      width: 200,

      render: (item, record) => (
        <Link
          style={{ padding: '0px !important' }}
          type='link'
          to={`/staffs/${record.id}`}
        >
          {item}
        </Link>
      ),
    },
    {
      title: 'นามสกุล',
      dataIndex: 'lastname',
      align: 'left',
      key: 'lastname',
      width: 200,
      render: (item, record) => (
        <Link
          style={{ padding: '0px !important' }}
          type='link'
          to={`/staffs/${record.id}`}
        >
          {item}
        </Link>
      ),
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'phoneNumber',
      align: 'center',
      key: 'phoneNumber',
    },

    {
      title: 'แก้ไข',
      dataIndex: 'edit',
      align: 'center',
      width: 150,
      render: (item, record) => (
        <Button
          type='link'
          icon={<EditOutlined />}
          onClick={() => history.push(`/staffs/${record.id}`)}
        />
      ),
    },

    {
      title: 'สถานะ',
      dataIndex: 'status',
      align: 'center',
      render: (item, record) => (
        <Switch checked={item} onChange={() => changeStatus(record.id)} />
      ),
    }
  ]

  return (
    <div>
      <Breadcrumb style={{ marginBottom: 16 }}>
        <Breadcrumb.Item>
          <Link to='/staffs'>เจ้าหน้าที่</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card
        title={
          <Input.Search
            placeholder='พิมพ์เพื่อค้นหา'
            onSearch={(text) => handelCustomSearch(text)}
            style={{ width: 320, marginRight: 10 }}
          />
        }
        extra={
          <Button
            icon={<PlusOutlined />}
            onClick={() => history.push('/staffs/add')}
          >
            เพิ่มผู้ใช้งาน
          </Button>
        }
      >
        <Table
          size='small'
          rowClassName={(record, index) =>
            index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
          }
          scroll={{ x: 960 }}
          rowKey={(record) => `${record.id}`}
          dataSource={staffList}
          columns={columns}
          loading={loading}
          onChange={handleTableChange}
          pagination={{
            position: 'bottom',
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50', '100'],
            total: pagination.total,
            current: pagination.currentPage,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </Card>
    </div>
  )
}

export default Staff
