import React from 'react'
import { Input, Form, Col, Modal, Space, Button } from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'

import { useFormik } from 'formik'

import { useGroup } from '../../../hooks'

export const GroupForm = ({ data, visible, onClose, loading }) => {
  const { setGroup } = useGroup()

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      id: data?.id || '',
      medicalname: data?.name || '',
      medicals: data?.medicals || [
        {
          id: '',
          medicalGroupId: '',
          name: '',
          unit: '',
        },
      ],
    },
    validate: (values) => {
      let errors = {}

      if (values?.medicalname === '' || !values?.medicalname) {
        errors.medicalname = 'กรุณาใส่ชื่อหมวดหมู่ยา'
      }

      errors.medicals = []
      if (values && values.medicals.length > 0) {
        values.medicals.forEach((item, index) => {
          errors.medicals.push({})
          if (values && values.medicals[index].name === '') {
            errors.medicals[index].name = 'กรุณาระบุชื่อยา'
          }

          if (values && values.medicals[index].unit === '') {
            errors.medicals[index].unit = 'กรุณาระบุหน่วย'
          }
        })
      }

      let check = errors.medicals.filter(
        (value) => Object.keys(value).length !== 0
      )

      if (check.length <= 0) {
        errors = {}
      }

      return errors
    },
    onSubmit: async (values, action) => {
      const res = await setGroup(values)

      if (res.status === 'success') {
        onClose()
      }
    },
  })
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
    resetForm,
  } = formik

  const handleAddInput = () => {
    let oldValues = values.medicals
    oldValues.push({ id: '', medicalGroupId: '', name: '', unit: '' })
    setFieldValue('medicals', oldValues)
  }

  const handleRemoveInput = (index) => {
    let array = values.medicals
    array.splice(index, 1)
    setFieldValue('medicals', array)
  }

  const handleInputChange = (evt, index) => {
    let array = values.medicals
    let field = evt.target.name
    let value = evt.target.value
    array[index][field] = value
    setFieldValue('medicals', array)
  }

  return (
    <Modal
      visible={visible}
      title={values.id ? 'แก้ไขหมวดหมู่ยา' : 'สร้างหมวดหมู่ยา'}
      onCancel={() => {
        resetForm()
        onClose()
      }}
      onOk={handleSubmit}
      footer={[
        <Button
          key='back'
          onClick={() => {
            resetForm()
            onClose()
          }}
        >
          ยกเลิก
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={handleSubmit}
        >
          บันทึก
        </Button>,
      ]}
    >
      <Col>
        <Form layout='vertical' style={{ marginBottom: 8 }}>
          <Form.Item
            label='ชื่อหมวดหมู่'
            help={touched.medicalname && errors.medicalname}
            validateStatus={
              touched.medicalname && errors.medicalname ? 'error' : undefined
            }
          >
            <Input
              name='medicalname'
              placeholder=''
              value={values.medicalname}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Item>
        </Form>
        <Form layout='horizontal' autoComplete='off'>
          <Form.Item label='ตัวยา' style={{ marginBottom: 0 }}>
            {values?.medicals?.length > 0 &&
              values?.medicals?.map((item, index) => (
                <Space
                  key={index}
                  style={{ display: 'flex', marginBottom: 8 }}
                  align='baseline'
                >
                  <Form.Item
                    style={{
                      display: 'inline-block',
                    }}
                    help={
                      errors.medicals &&
                      errors.medicals.length > 0 &&
                      errors.medicals[index] &&
                      errors.medicals[index]?.name
                    }
                    validateStatus={
                      errors.medicals &&
                      errors.medicals.length > 0 &&
                      errors.medicals[index] &&
                      errors?.medicals[index]?.name
                        ? 'error'
                        : undefined
                    }
                  >
                    <Input
                      name='name'
                      placeholder='ระบุชื่อยา'
                      value={values.medicals[index].name}
                      onChange={(e) => handleInputChange(e, index)}
                      onBlur={handleBlur}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{
                      display: 'inline-block',
                    }}
                    help={
                      errors.medicals &&
                      errors.medicals.length > 0 &&
                      errors.medicals[index] &&
                      errors.medicals[index]?.unit
                    }
                    validateStatus={
                      errors.medicals &&
                      errors.medicals.length > 0 &&
                      errors.medicals[index] &&
                      errors.medicals[index]?.unit
                        ? 'error'
                        : undefined
                    }
                  >
                    <Input
                      name={`unit`}
                      placeholder='หน่วย'
                      value={values.medicals[index].unit}
                      onChange={(e) => handleInputChange(e, index)}
                      onBlur={handleBlur}
                    />
                  </Form.Item>
                  {index > 0 && (
                    <MinusCircleOutlined
                      onClick={() => handleRemoveInput(index)}
                    />
                  )}
                </Space>
              ))}

            <Form.Item style={{ width: '82%' }}>
              <Button
                type='dashed'
                onClick={() => handleAddInput()}
                block
                icon={<PlusOutlined />}
              >
                เพิ่ม ช่องระบุชื่อยา
              </Button>
            </Form.Item>
          </Form.Item>
        </Form>
      </Col>
    </Modal>
  )
}

export default GroupForm
