import { useState, useEffect } from 'react'
import firebase from '../config/firebase'

import { useAppContext } from '../context'

export function useGroup(id) {
  const { setError, setMessage } = useAppContext()
  const [loading, setLoading] = useState(false)
  const [groupList, setGroupList] = useState([])
  const [groupData, setGroupData] = useState()

  const [pagination, setPagination] = useState({
    page: 0,
    page_total: 10,
    total: 10,
    currentPage: 1,
    search: '',
  })

  useEffect(() => {
    id && getGroupById(id)
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    getGroups()
    // eslint-disable-next-line
  }, [])

  async function getGroups(page = 0, page_total = 10, search = '') {
    setLoading(true)
    let data = { page, page_total, search }

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('medicalGroupList')(data)
        .catch((error) => ({ error }))

      if (res.data) {
        setGroupList(res.data.list)
        setPagination({
          page: page,
          page_total: page_total,
          total: res.data.total,
          currentPage: page + 1,
          search: search,
        })

        setLoading(false)

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Get Group Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Get Group Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function setGroup(data) {
    setLoading(true)

    const json = {
      id: data.id,
      name: data.medicalname,
      medicals: data?.medicals.map((item) => ({
        id: item?.id || '',
        medicalGroupId: item?.medicalGroupId || '',
        name: item?.name || '',
        unit: item?.unit || '',
      })),
    }

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('setMedicalGroup')(json)
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)

        setMessage({
          variant: 'success',
          message: 'Success',
        })
        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Set Group Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Set Group Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function deleteGroup(id) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('deleteMedicalGroup')({ id })
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)

        setMessage({
          variant: 'success',
          message: 'Success',
        })
        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Delete Group Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Delete Group Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function setGroupUsed(data) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('statusMedicalGroup')(data)
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)

        setMessage({
          variant: 'success',
          message: 'Success',
        })
        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Set Used Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Set Used Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function getGroupById(id) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('medicalGroupDetail')({ id })
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)
        setGroupData(res.data)

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Set Group Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Set Group Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  function handleTableChange(paginationChange, filters, sorter) {
    const { search } = pagination
    getGroups(paginationChange.current - 1, paginationChange.pageSize, search)
  }

  function handelCustomSearch(text) {
    const { page, page_total } = pagination
    getGroups(page, page_total, text)
  }

  return {
    loading,
    getGroups,
    groupList,
    pagination,
    handleTableChange,
    handelCustomSearch,
    setGroup,
    groupData,
    deleteGroup,
    setGroupUsed,
    getGroupById,
  }
}
