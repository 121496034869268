import { useState, useEffect } from 'react'
import firebase from '../config/firebase'

import { useAppContext } from '../context'

export function useStaff(id) {
  const { setError, setMessage } = useAppContext()
  const [loading, setLoading] = useState(false)
  const [staffList, setStaffList] = useState([])
  const [staffData, setStaffData] = useState()

  const [pagination, setPagination] = useState({
    page: 0,
    page_total: 10,
    total: 10,
    currentPage: 1,
    search: '',
  })

  useEffect(() => {
    id && getStaffById(id)
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    getStaffs()
    // eslint-disable-next-line
  }, [])

  async function getStaffs(page = 0, page_total = 10, search = '') {
    setLoading(true)
    let data = { page, page_total, search }

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('userList')(data)
        .catch((error) => ({ error }))

      if (res.data) {
        setStaffList(res.data)
        setPagination({
          page: page,
          page_total: page_total,
          total: res.data.total,
          currentPage: page + 1,
        })

        setLoading(false)

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Get Staff Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Get Staff Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function setStaff(data) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('setUser')({ ...data, password_again: undefined })
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)

        setMessage({
          variant: 'success',
          message: 'Success',
        })

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Set Staff Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Set Staff Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function getStaffById(id) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('userDetail')({ id })
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)
        setStaffData(res.data)

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Set Staff Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Set Staff Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  function handleTableChange(paginationChange, filters, sorter) {
    getStaffs(paginationChange.current - 1, paginationChange.pageSize)
  }

  function handelCustomSearch(text) {
    const { page, page_total } = pagination
    getStaffs(page, page_total, text)
  }

  function changeStatus(id) {
    setLoading(true)

    firebase
      .app()
      .functions('asia-southeast2')
      .httpsCallable('statusUser')({ id })
      .catch((error) => ({ error })).then(() => {
        getStaffs()
      })
  }

  return {
    loading,
    getStaffs,
    staffList,
    pagination,
    handleTableChange,
    handelCustomSearch,
    setStaff,
    changeStatus,
    staffData,
  }
}
