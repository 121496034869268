import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyD8eXeNPHD4H3DVu3YVNX9CyxxfZwKPirw',
  authDomain: 'siriraj-gi-dev.firebaseapp.com',
  projectId: 'siriraj-gi-dev',
  storageBucket: 'siriraj-gi-dev.appspot.com',
  messagingSenderId: '560890468024',
  appId: '1:560890468024:web:f29853c851ca6e3d1d864d',
  measurementId: 'G-D07BB86BQ1',
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

export default firebase
