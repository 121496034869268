import { useState, useEffect } from 'react'
import firebase from '../config/firebase'

import { useAppContext } from '../context'

export function usePatient(id) {
  const { setError, setMessage } = useAppContext()
  const [loading, setLoading] = useState(false)
  const [patientList, setPatientList] = useState()
  const [patientData, setPatientData] = useState()
  const [patientNoti, setPatientNoti] = useState()

  const [pagination, setPagination] = useState({
    page: 0,
    page_total: 10,
    total: 10,
    currentPage: 1,
    search: '',
  })

  useEffect(() => {
    id && getPatientById(id) && getPatientNotiById(id)
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    getPatients()
    // eslint-disable-next-line
  }, [])

  async function getPatients(page = 0, page_total = 10, search = '') {
    setLoading(true)
    let data = { page, page_total, search }

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('patientList')(data)
        .catch((error) => ({ error }))

      if (res.data) {
        setPatientList(res.data.list)

        setPagination({
          page: page,
          page_total: page_total,
          total: res.data.total,
          currentPage: page + 1,
          search: search,
        })

        setLoading(false)

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Get Patients Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Get Patients Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function setPatient(data) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('setPatient')(data)
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)

        setMessage({
          variant: 'success',
          message: 'Success',
        })

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Set Patient Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Set Patient Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function editPatient(data) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('editPatient')(data)
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)

        setMessage({
          variant: 'success',
          message: 'Success',
        })

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Edit Patient Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Edit Patient Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function getPatientById(id) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('patientDetail')({ id })
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)
        setPatientData(res.data)

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Get Patient Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Get Patient Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function getPatientNotiById(id) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('patientNotifications')({ id })
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)
        setPatientNoti(res.data)

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Get Patient Notifications Error',
        })

        setLoading(false)
        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Get Patient Notifications Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function setPatientNotiStatus(data) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('setPatientCompleted')(data)
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)
        setPatientNoti(res.data)

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Set Patient Status Error',
        })

        setLoading(false)
        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Set Patient Status Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function setPatientNotiTime(data) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('patientCreateNotifications')(data)
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)
        setPatientNoti(res.data)

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Set Patient Notification Time Error',
        })

        setLoading(false)
        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Set Patient Notification Time Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  function handleTableChange(paginationChange, filters, sorter) {
    getPatients(paginationChange.current - 1, paginationChange.pageSize)
  }

  function handelCustomSearch(text) {
    const { page, page_total } = pagination
    getPatients(page, page_total, text)
  }

  return {
    loading,
    getPatients,
    patientList,
    pagination,
    handleTableChange,
    handelCustomSearch,
    setPatient,
    editPatient,
    patientData,
    patientNoti,
    setPatientNotiStatus,
    setPatientNotiTime,
    getPatientNotiById,
    getPatientById,
  }
}
