import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Card,
  Table,
  Breadcrumb,
  Button,
  Input,
  Checkbox,
  Space,
  Popconfirm,
} from 'antd'
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import GroupForm from './GroupForm'
import { useGroup } from '../../../hooks'

export const Group = () => {
  const {
    loading,
    getGroups,
    groupList,
    pagination,
    handleTableChange,
    handelCustomSearch,
    deleteGroup,
    setGroupUsed,
  } = useGroup()

  const [formDialog, setFormDialog] = useState({
    open: false,
    data: null,
  })

  const [refetch, setRefetch] = useState(false)

  useEffect(() => {
    if (refetch) {
      const { page, page_total, search } = pagination
      getGroups(page, page_total, search)
      setRefetch(false)
    }
    // eslint-disable-next-line
  }, [refetch])

  const columns = [
    {
      title: 'เลือกใช้',
      dataIndex: 'used',
      key: 'no.',
      align: 'center',
      width: 100,
      render: (data, record) => {
        return (
          <Checkbox
            checked={data}
            onChange={async () => {
              await setGroupUsed({ id: record.id, used: !record.used })
              setRefetch(true)
            }}
          />
        )
      },
    },
    {
      title: 'ลำดับ',
      dataIndex: 'no.',
      key: 'no.',
      align: 'center',
      render: (data, row, index) => {
        return pagination.page * pagination.page_total + index + 1
      },
    },
    {
      title: 'ชื่อหมวดหมู่ยา',
      dataIndex: 'name',
      align: 'left',
      key: 'firstname',
    },
    {
      title: 'ตัวยา',
      dataIndex: 'madicals',
      align: 'center',
      key: 'lastname',
      width: 300,
      render: (item, record) => record.medicals.length,
    },
    {
      title: 'หน่วย',
      dataIndex: 'unit',
      align: 'left',
      key: 'unit',
      width: 100,
      render: (item, record) =>
        record.medicals.map((medical, i) =>
          i === 0 ? medical.unit : `, ${medical.unit}`
        ),
    },

    {
      title: 'เครื่องมือ',
      dataIndex: 'edit',
      align: 'right',
      render: (item, record) => (
        <Space>
          <Button
            type='link'
            icon={<EditOutlined />}
            onClick={() =>
              setFormDialog({
                open: true,
                data: record,
              })
            }
          />
          <Popconfirm
            title='ต้องการลบหมวดหมู่ยานี้?'
            onConfirm={async () => {
              await deleteGroup(record.id)
              setRefetch(true)
            }}
            okText='ใช่'
            cancelText='ไม่'
          >
            <Button danger type='link' icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <div>
      <Breadcrumb style={{ marginBottom: 16 }}>
        <Breadcrumb.Item>ระบบจัดการด้านยา</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to='/groups'>หมวดหมู่ยา</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card
        title={
          <Input.Search
            placeholder='พิมพ์เพื่อค้นหา'
            onSearch={(text) => handelCustomSearch(text)}
            style={{ width: 320, marginRight: 10 }}
          />
        }
        extra={
          <Button
            icon={<PlusOutlined />}
            onClick={() =>
              setFormDialog({
                open: true,
                data: null,
              })
            }
          >
            เพิ่มรายการ
          </Button>
        }
      >
        <Table
          // size='small'
          rowClassName={(record, index) =>
            index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
          }
          scroll={{ x: 960 }}
          rowKey={(record) => `${record.id}`}
          dataSource={groupList || []}
          columns={columns}
          loading={loading}
          onChange={handleTableChange}
          pagination={{
            position: 'bottom',
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50', '100'],
            total: pagination.total,
            current: pagination.currentPage,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
        {formDialog.open && (
          <GroupForm
            visible={formDialog.open}
            onClose={() => {
              setFormDialog({
                open: false,
                data: null,
              })
              setRefetch(true)
            }}
            data={formDialog.data}
            loading={loading}
          />
        )}
      </Card>
    </div>
  )
}

export default Group
