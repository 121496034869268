import React, { useEffect, useState } from 'react'
import { Card, Breadcrumb, Row, Col, Typography, Space, Button, Form, Input, Image } from 'antd'
import {
  MessageList,
  ChatList,
  Avatar,
  Navbar,
} from 'react-chat-elements'

import { SendOutlined } from '@ant-design/icons'

import AvatarUrl from '../../Assets/Images/avatar.svg'

import firebase from '../../config/firebase'
import { useAuth } from '../../hooks'

import { isWebUri } from 'valid-url';

const { Text, Link } = Typography

export const Chat = () => {
  const [mouted, setMouted] = useState(false)
  const [list, setList] = useState([])
  const [fullList, setFullList] = useState([])
  const [selector, setSelector] = useState({
    id: '',
    avatar: '',
  })

  useEffect(() => {
    setMouted(true)

    const onSnapshot = firebase.firestore().collection('rooms').orderBy('updatedAt', 'desc').onSnapshot(snap => {
      const resultList = snap.docs.map(s => ({ ...s.data(), id: s.id, updatedAt: s.get('updatedAt').toDate() }))
      setList(resultList)
      setFullList(resultList)
    })

    return () => {
      onSnapshot()
      setMouted(false)
    }
  }, [])

  const handelCustomSearch = (text) => {
    setList(fullList.filter(s => s.displayName?.includes(text) || s.hospitalNumber?.includes(text) || s.phoneNumber?.includes(text)))
  }

  return (
    mouted && (
      <div>
        <Breadcrumb style={{ marginBottom: 16 }}>
          <Breadcrumb.Item>
            <>การสนทนา</>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col lg={8} flex={2}>
            <Card
              style={{ background: 'white', minHeight: '80vh', maxHeight: '80vh', overflow: 'auto' }}
              title={
                <Input.Search
                  placeholder='พิมพ์เพื่อค้นหา'
                  onSearch={(text) => handelCustomSearch(text)}
                // style={{ width: 320, marginRight: 10 }}
                />
              }
            >
              <ChatList
                className='chat-list'
                dataSource={list.map(s => ({
                  id: s.id,
                  avatar: s.active ? s.pictureUrl : AvatarUrl,
                  active: s.active,
                  alt: s.statusMessage,
                  title: s.hospitalNumber ? <Link style={{ fontWeight: '500' }}>{s.hospitalNumber}</Link> : <Text style={{ fontWeight: 'bold', color: 'gray' }}>ยังไม่ได้ยืนยันตัวตน</Text>,
                  hn: s.hospitalNumber || '',
                  displayName: s.displayName,
                  subtitle: (
                    <Space direction='vertical' size={0}>
                      <Text style={{ fontWeight: 500 }}>{s.displayName}</Text>
                      <Text>เบอร์มือถือ {s.phoneNumber}</Text>
                    </Space>
                  ),
                  date: s.updatedAt,
                  unread: s.newMessage,
                }))}
                onClick={(data) => {
                  setSelector(data)
                }}
              />
            </Card>
          </Col>
          <Col lg={16} flex={7}>
            <Navbar type='dark' />
            <ChatCard data={selector} />
          </Col>
        </Row>
      </div>
    )
  )
}

const scoreText = [
  '', 'แย่', 'น้อย', 'ปานกลาง', 'ดี', 'ดีมาก'
]

function ChatCard({ data }) {
  const { userInfo } = useAuth()
  const [messageList, setMessageList] = useState([])

  const [message, setMessage] = useState('')

  const handleSendMessage = () => {
    if (message) {
      firebase.firestore().collection(`rooms/${data.id}/messages`).add({
        id: null,
        senderId: userInfo.id,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        isSend: false,
        type: 'text',
        text: message
      }).then(() => {
        setMessage('')
      })
    }
  }

  useEffect(() => {
    if (data.id) {

      firebase.app().functions('asia-southeast2').httpsCallable('chatRead')({ userId: data.id }).catch(err => {
        console.error(err)
      })

      const onSnapshot = firebase.firestore().collection(`rooms/${data.id}/messages`).orderBy('createdAt').onSnapshot(snap => {
        setMessageList(snap.docs.map(s => {
          const doc = s.data()
          doc.position = s.get('senderId') ? 'right' : 'left'
          doc.date = s.get('createdAt') ? s.get('createdAt').toDate() : null
          if (doc.type === 'text') {
            if (s.get('text') && isWebUri(s.get('text'))) {
              doc.text = (<a href={s.get('text')} target='_blank' rel='noreferrer'>{s.get('text')}</a>)
            } else {
              doc.text = (<Space>
                <Avatar
                  src={s.get('senderId') ? AvatarUrl : data.active ? data.avatar : AvatarUrl}
                  alt={'logo'}
                  size='xsmall'
                  type='circle flexible'
                />
                {s.get('text')}
              </Space>)
            }
          } else if (doc.type === 'image') {
            doc.type = 'text'
            doc.text = (<Image
              style={{ maxWidth: '200px' }}
              src={doc.previewImageUrl}
              alt={'logo'}
            />)
          } else if (doc.type === 'arrayText') {
            doc.type = 'text'
            doc.text = <>
              <Typography style={{ fontWeight: 'bold', fontSize: '1rem' }}>ผลการประเมิน</Typography>
              {doc.items.map((item, i) => {
                return (<Typography key={i}>
                  <br />
                  {item.question} <br />
                  <Typography style={{ fontWeight: 'bold' }}>{scoreText[item.score]}</Typography>
                </Typography>)
              })}
            </>
          }

          return doc
        }));
      })

      return () => {
        onSnapshot()
      }
    }
  }, [data])

  return (
    <>
      <Card
        style={{ background: 'white', minHeight: '65vh', maxHeight: '65vh', overflow: 'auto', }}
        bodyStyle={{
          width: '100%'
        }}
      >
        <MessageList
          className='message-list'
          lockable={true}
          toBottomHeight={'100%'}
          dataSource={messageList}
          downButton={true}
        />
      </Card>
      <Form onFinish={handleSendMessage}>
        <Input
          addonAfter={<Button
            type='link'
            icon={<SendOutlined />}
            shape='circle'
            htmlType='submit'
          />}
          size='large'
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
      </Form>
    </>
  )
}

export default Chat
