import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Card,
  Table,
  Breadcrumb,
  Button,
  Input,
  Checkbox,
  Space,
  Popconfirm,
  Select,
} from 'antd'
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import RegimenForm from './RegimenForm'
import { useRegimen, useGroup } from '../../../hooks'
import moment from 'moment'

const truncateString = (string, maxLength = 120) => {
  if (!string) return null
  if (string.length <= maxLength) return string
  return `${string.substring(0, maxLength)}...`
}

const preparationType = [
  {
    number: 1,
    label: 'Split dose regimen',
  },
  {
    number: 2,
    label: 'Day-before regimen',
  },
]

export const Regimen = () => {
  const {
    loading,
    getRegimens,
    regimenList,
    pagination,
    handleTableChange,
    handelCustomSearch,
    deleteRegimen,
    setRegimenUsed,
    handelFilter,
  } = useRegimen()

  const { groupList } = useGroup()

  const [formDialog, setFormDialog] = useState({
    open: false,
    data: null,
  })

  const [refetch, setRefetch] = useState(false)

  useEffect(() => {
    if (refetch) {
      const { page, page_total, search, filter } = pagination
      getRegimens(page, page_total, search, filter)
    }
    setRefetch(false)
    // eslint-disable-next-line
  }, [refetch])

  const columns = [
    {
      title: 'เลือกใช้',
      dataIndex: 'used',
      width: 100,
      key: 'no.',
      align: 'center',
      render: (data, record) => {
        return (
          <Checkbox
            checked={data}
            onChange={async () => {
              await setRegimenUsed({ id: record.id, used: !record.used })
              setRefetch(true)
            }}
          />
        )
      },
    },
    {
      title: 'ลำดับ',
      dataIndex: 'no.',
      key: 'no.',
      align: 'center',
      render: (data, row, index) => {
        return pagination.page * pagination.page_total + index + 1
      },
    },
    {
      title: 'ชื่อรายการยา',
      dataIndex: 'name',
      align: 'left',
      key: 'firstname',
    },
    {
      title: 'จำนวนซอง',
      dataIndex: 'medicalAmount',
      align: 'center',
      key: 'firstname',
    },
    {
      title: 'ผสมน้ำปริมาณ',
      dataIndex: 'waterAmount',
      align: 'center',
      key: 'firstname',
    },
    {
      title: 'หน่วย',
      dataIndex: 'waterUnit',
      align: 'center',
      key: 'firstname',
    },
    {
      title: 'ช่วงเวลา',
      dataIndex: 'startTime',
      align: 'center',
      key: 'firstname',
      render: (item, record) =>
        moment(record.startTime, 'HH:mm:ss').format('HH:mm') +
        ' - ' +
        moment(record.endTime, 'HH:mm:ss').format('HH:mm') +
        ' น.',
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'description',
      align: 'center',
      key: 'lastname',
      width: 400,
      render: (item, record) => truncateString(item),
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'edit',
      align: 'right',
      width: 150,
      render: (item, record) => (
        <Space>
          <Button
            type='link'
            icon={<EditOutlined />}
            onClick={() => {
              setFormDialog({
                open: true,
                data: record,
              })
            }}
          />
          <Popconfirm
            title='ต้องการลบรายการรับประทานยานี้?'
            onConfirm={async () => {
              await deleteRegimen(record.id)
              setRefetch(true)
            }}
            okText='ใช่'
            cancelText='ไม่'
          >
            <Button danger type='link' icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <div>
      <Breadcrumb style={{ marginBottom: 16 }}>
        <Breadcrumb.Item>ระบบจัดการด้านยา</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to='/regimens'>รายการรับประทานยา</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card
        title={
          <Space>
            <Select
              defaultValue=''
              style={{ width: 367 }}
              onChange={(e) => handelFilter(e)}
            >
              <Select.Option value=''>แสดงทั้งหมด</Select.Option>

              {groupList &&
                groupList.map((item, index) =>
                  preparationType.map((prepare, index) => (
                    <Select.Option
                      key={`${prepare.label}-${item.name}`}
                      value={`${prepare.number}-${item.id}`}
                    >
                      {prepare.label} - {item.name}
                    </Select.Option>
                  ))
                )}
            </Select>
            <Input.Search
              placeholder='พิมพ์เพื่อค้นหา'
              onSearch={(text) => handelCustomSearch(text)}
              style={{ width: 320, marginRight: 10 }}
            />
          </Space>
        }
        extra={
          <Button
            icon={<PlusOutlined />}
            onClick={() =>
              setFormDialog({
                open: true,
                data: null,
              })
            }
          >
            เพิ่มรายการ
          </Button>
        }
      >
        <Table
          // size='small'
          rowClassName={(record, index) =>
            index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
          }
          scroll={{ x: 960 }}
          rowKey={(record) => `${record.id}`}
          dataSource={regimenList || []}
          columns={columns}
          loading={loading}
          onChange={handleTableChange}
          pagination={{
            position: 'bottom',
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50', '100'],
            total: pagination.total,
            current: pagination.currentPage,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
        {formDialog.open && (
          <RegimenForm
            visible={formDialog.open}
            onClose={() => {
              setFormDialog({
                open: false,
                data: null,
              })
              setRefetch(true)
            }}
            data={formDialog.data}
          />
        )}
      </Card>
    </div>
  )
}

export default Regimen
