import { Spin } from 'antd'
import styled from 'styled-components'

const SpinerScreen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
  width: 100vw;
  height: 100vh;
`

const FullScreenLoading = () => (
  <SpinerScreen>
    <Spin size='large' />
  </SpinerScreen>
)

export default FullScreenLoading
