import React, { useState } from 'react'
import { Layout, Menu, Image, Modal } from 'antd'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  LogoutOutlined,
  MessageOutlined,
  TeamOutlined,
  UserOutlined,
  MedicineBoxOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import LogoImageUrl from '../Assets/Images/logo.svg'
import { useAuth } from '../hooks'
import { useAppContext } from '../context'

import Notify from '../Components/Notify'

import theme from '../theme'

const { Header, Sider, Content, Footer } = Layout

const Logo = styled.div`
  margin: 1rem;
  text-align: center;

  h3 {
    color: ${(props) =>
    props.theme.dark ? props.theme.color.light : props.theme.color.primary};
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
    font-size: 16px;
  }
`

const LogoImage = styled(Image)`
  height: ${(props) => (props.collapsed ? '38px' : '76px')}
  width: ${(props) => (props.collapsed ? '45px' : '89px')}
  path {
    fill: ${(props) =>
    props.theme.dark ? 'white' : props.theme.color.primary};
    }
  
`

const LayoutHeader = styled(Header)`
  background: #254685;
  padding-left: 0;
  padding-right: 1rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  z-index: 9;
  align-items: center;
`

const LayoutFooter = styled(Footer)`
  background: ${(props) => props.theme.color.light};
  color: ${(props) => props.theme.color.grey};
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
`

const UnfoldTrigger = styled(MenuUnfoldOutlined)`
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  color: ${(props) => props.theme.color.light};
`

const FoldTrigger = styled(MenuFoldOutlined)`
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;

  color: ${() => theme.color.light};
`

const SideBar = styled(Sider)`
  min-height: 100vh;
  background-color: ${() => theme.color.darkPrimary};

  .ant-menu.ant-menu-sub.ant-menu-inline{
    background-color: ${() => theme.color.darkPrimary} !important;

    .ant-menu-item.ant-menu-item-only-child:not(.ant-menu-item-selected):hover {
      background-color: ${() => theme.color.light || '#FFFFFF'} !important;
  
      a {
        color: ${() => theme.color.darkPrimary} !important;
      }
  
      span {
        color: ${(props) => theme.color.darkPrimary};
      }
    }
  }
`

const SidebarMenu = styled(Menu)`
  background-color: ${() => theme.color.darkPrimary} !important;
`

const MenuItem = styled(Menu.Item)`
  background-color: ${(props) => props.theme.color.darkPrimary};
  span {
    color: ${(props) => props.theme.color.light};
  }

  a:hover {
    color: ${(props) => props.theme.color.light} !important;
  }

  :not(.ant-menu-item-selected):hover {
    background-color: ${(props) => props.theme.color.light} !important;

    a {
      color: ${(props) => props.theme.color.darkPrimary} !important;
    }

    span {
      color: ${(props) => props.theme.color.darkPrimary};
    }
  }
`

const SubMenu = styled(Menu.SubMenu)`
  // background-color: ${(props) => props.theme.color.darkPrimary};
  span {
    color: ${(props) => props.theme.color.light};
  }

  a:hover {
    color: ${(props) => props.theme.color.light} !important;
  }

  // :not(.ant-menu-item-selected):hover {
  //   background-color: ${(props) => props.theme.color.light} !important;

  //   a {
  //     color: ${(props) => props.theme.color.darkPrimary} !important;
  //   }

  //   span {
  //     color: ${(props) => props.theme.color.darkPrimary};
  //   }
  // }
`

const key = {
  '': 'index',
  users: 'users',
  signout: 'signout',
  staffs: 'staffs',
  patients: 'patients',
  mms: 'mms',
  groups: 'groups',
  regimens: 'regimens',
}

const subKey = ['groups', 'regimens']

function MainLayout({ children }) {
  const { message } = useAppContext()

  const { logout } = useAuth()
  const [collapsed, setCollapsed] = useState(false)

  const location = useLocation()

  const currentPath = location.pathname.split('/')[1]

  const toggle = () => {
    setCollapsed(!collapsed)
  }

  function confirm() {
    Modal.confirm({
      title: 'ต้องการออกจากระบบ',
      icon: null,
      okText: 'ใช่',
      cancelText: 'ยกเลิก',
      onOk: logout,
    })
  }

  return (
    <Layout>
      <Notify notify={message} />
      <SideBar
        trigger={null}
        collapsible
        collapsed={collapsed}
        width='240px'
        theme={theme.dark ? 'dark' : 'light'}
      >
        <Logo className='logo' collapsed={collapsed}>
          <LogoImage
            src={LogoImageUrl}
            preview={false}
          // style={{ width: collapsed ? 45 : 108, height: collapsed ? 38 : 92 }}
          />

          {!collapsed && <h3>SiConnect Admin</h3>}
        </Logo>
        <SidebarMenu
          mode='inline'
          defaultSelectedKeys={[key[currentPath]]}
          defaultOpenKeys={[
            subKey.some((k) => k === key[currentPath]) ? 'mms' : '',
          ]}
          theme={theme.dark ? 'dark' : 'light'}
        >
          <MenuItem key='index' icon={<HomeOutlined />}>
            <Link to={'/'}>หน้าแรก</Link>
          </MenuItem>
          <SubMenu
            key='mms'
            icon={<MedicineBoxOutlined />}
            title='ระบบจัดการด้านยา'
          >
            <Menu.Item key='groups'>
              <Link to={'/groups'}>หมวดหมู่ยา</Link>
            </Menu.Item>
            <Menu.Item key='regimens'>
              <Link to={'/regimens'}>รายการรับประทานยา</Link>
            </Menu.Item>
          </SubMenu>

          <MenuItem key='patients' icon={<TeamOutlined />}>
            <Link to={'/patients'}>ข้อมูลผู้เข้ารักษา</Link>
          </MenuItem>
          <MenuItem key='chat' icon={<MessageOutlined />}>
            <Link to={'/chat'}>การสนทนา</Link>
          </MenuItem>
          <MenuItem key='staffs' icon={<UserOutlined />}>
            <Link to={'/staffs'}>เจ้าหน้าที่</Link>
          </MenuItem>
          <MenuItem key='signout' icon={<LogoutOutlined />} onClick={confirm}>
            ออกจากระบบ
          </MenuItem>
        </SidebarMenu>
      </SideBar>
      <Layout className='site-layout' theme='light'>
        <LayoutHeader>
          {collapsed ? (
            <UnfoldTrigger className='trigger' onClick={toggle} />
          ) : (
            <FoldTrigger className='trigger' onClick={toggle} />
          )}
        </LayoutHeader>
        <Content style={{ margin: 30 }}>{children}</Content>
        <LayoutFooter>SiConnect Admin ©2021</LayoutFooter>
      </Layout>
    </Layout>
  )
}

export default MainLayout
