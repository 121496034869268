import React, { useState, useEffect } from 'react'
import { useParams, Link as RouterLink } from 'react-router-dom'
import { EditOutlined } from '@ant-design/icons'
import {
  Card,
  Table,
  Breadcrumb,
  Typography,
  Space,
  Avatar,
  Divider,
  // Switch,
  Button,
  Modal,
  Form,
  Input,
} from 'antd'
import moment from 'moment'
import PatientCreateNoti from './PatientCreateNoti'
import PatientStatus from './PatientStatus'

import { usePatient } from '../../hooks'

import { PlusOutlined } from '@ant-design/icons'

import AvatarUrl from '../../Assets/Images/avatar.svg'
const { Text, Link } = Typography

export const Patient = () => {
  let { id } = useParams()
  const { loading, patientData, patientNoti, getPatientNotiById, getPatientById, editPatient,
    // setPatientNotiStatus
  } = usePatient(id)

  const [editModal, setEditModal] = useState({
    data: {
      hospitalNumber: '',
      fullname: "",
      phoneNumber: "",
    },
    open: false,
    enable: false,
    loading: false,
  })

  const [formDialog, setFormDialog] = useState({
    open: false,
    data: null,
  })

  const [formDialogStatus, setFormDialogStatus] = useState({
    open: false,
    data: null,
  })

  const [refetch, setRefetch] = useState(false)

  const handleClickEdit = () => {
    setEditModal({ ...editModal, open: true })
  }

  const handleSubmitEdit = (data) => {
    setEditModal({ ...editModal, loading: true })
    editPatient({ ...data, id }).then(() => {
      setRefetch(true)
    })
    // setEditModal({ ...editModal, data, open: false })
  }

  const handleCloseEdit = () => {
    setEditModal({ ...editModal, open: false })
  }

  useEffect(() => {
    if (refetch) {
      getPatientById(id)
      getPatientNotiById(id)
      setRefetch(false)
    }
    // eslint-disable-next-line
  }, [refetch, setRefetch, getPatientNotiById, getPatientById])

  useEffect(() => {
    if (!loading && patientData) {
      setEditModal({
        data: {
          hospitalNumber: patientData.hospitalNumber,
          fullname: patientData.fullname,
          phoneNumber: patientData.phoneNumber,
        },
        open: false,
        enable: true,
      })
    }
  }, [loading, patientData])

  const columns = [
    {
      title: 'ลำดับ',
      dataIndex: 'no.',
      width: 50,
      key: 'no.',
      align: 'center',
      render: (data, row, index) => {
        return index + 1
      },
    },

    {
      title: 'วัน-เดือน-ปี',
      dataIndex: 'notifyTime',
      align: 'center',
      key: 'notifyDate',
      render: (item, record) => moment(item).format('DD-MM-YYYY'),
    },
    {
      title: 'เวลา',
      dataIndex: 'notifyTime',
      align: 'center',
      key: 'notifyTime',
      render: (item, record) => moment(item).format('HH:mm') + ' น.',
    },

    {
      title: 'รายการแจ้งเตือน',
      dataIndex: 'type',
      align: 'center',
      key: 'type',
      render: (type, record) =>
        type === 1
          ? 'เตือนกินยา'
          : type === 2
            ? 'เตือนปรับยา'
            : type === 3
              ? 'เตือนส่งภาพตรวจ'
              : '',
    },

    {
      title: 'ข้อความ',
      dataIndex: 'message',
      align: 'center',
      key: 'message',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      align: 'center',
      width: 150,
      key: 'status',
      render: (item, record) =>
        item ? (
          <Text style={{ color: '#4290F7' }}>ส่งแล้ว</Text>
        ) : (
          <Text style={{ color: '#EFC342' }}>รอส่ง</Text>
        ),
    },
  ]

  return (
    <div>
      <Breadcrumb style={{ marginBottom: 16 }}>
        <Breadcrumb.Item>
          <RouterLink to='/patients'>ข้อมูลผู้เข้ารักษา</RouterLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>การแจ้งเตือนผู้เข้ารักษา</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <Card.Grid
          style={{
            width: '20%',
            textAlign: 'center',
            border: 0,
            boxShadow: 'none',
          }}
          hoverable={false}
        >
          <Avatar
            size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
            src={AvatarUrl}
          />
        </Card.Grid>
        <Card.Grid
          style={{
            width: '30%',
            textAlign: 'left',
            border: 0,
            boxShadow: 'none',
          }}
          hoverable={false}
        >
          <Space direction='vertical'>
            <span>
              <Link target='_blank'>{patientData?.hospitalNumber}</Link>
              <Button
                type='link'
                icon={<EditOutlined />}
                onClick={handleClickEdit}
                disabled={!editModal.enable}
              />
            </span>
            <span>
              <Text strong>ชื่อ </Text>
              <Text> {patientData?.fullname}</Text>
            </span>
            <span>
              <Text strong>เบอร์มือถือ </Text>
              <Text> {patientData?.phoneNumber}</Text>
            </span>
            <span>
              <Text strong>การเตรียมตัว : </Text>
              <Text>
                {patientData?.notiLists?.preparationType === 1
                  ? 'Spit dose regimen'
                  : patientData?.notiLists?.preparationType === 2
                    ? 'Day-before regimen'
                    : ''}
              </Text>
            </span>
            <span>
              <Text strong>รายละเอียด : </Text>
              <Text> {patientData?.description}</Text>
            </span>
          </Space>
        </Card.Grid>
        <Card.Grid
          style={{
            width: '40%',
            textAlign: 'left',
            border: 0,
            boxShadow: 'none',
          }}
          hoverable={false}
        >
          <Space direction='vertical'>
            <Text></Text>
            <Text></Text>
            <Text></Text>
            <Text></Text>
            <Text></Text>
            <Text></Text>
            <span>
              <Text strong>วันนัดส่องกล้อง : </Text>
              <Text>
                {' '}
                {patientData?.notiLists?.bookingDate ? moment(patientData?.notiLists?.bookingDate).format(
                  'DD-MM-YYYY HH:mm น.'
                ) : '-'}
              </Text>
            </span>
            <span>
              {/* {patientData?.status ? (<Switch
                disabled={patientData?.status === '0'}
                checked={patientData?.status === '1'}
                onChange={() =>
                  setPatientNotiStatus({ id }).then(() => {
                    setRefetch(true)
                  })
                  // setFormDialogStatus({
                  //   open: true,
                  //   data: { id },
                  // })
                }
              />) : (<></>)} */}
              <Text strong> สถานะการรักษา : </Text>
              <Text>
                {patientData?.status === 0
                  ? 'เสร็จสิ้น'
                  : patientData?.status === 1
                    ? 'ดำเนินการ'
                    : ''}
              </Text>
            </span>
          </Space>
        </Card.Grid>

        <Divider />
        <Card
          style={{
            padding: 24,
          }}
          hoverable={false}
          title={<Text strong>การแจ้งเตือนผู้เข้าารักษา</Text>}
          extra={
            <Button
              icon={<PlusOutlined />}
              onClick={() =>
                setFormDialog({
                  open: true,
                  data: { id },
                })
              }
            >
              เพิ่มรายการ
            </Button>
          }
        >
          <Table
            size='small'
            rowClassName={(record, index) =>
              index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
            }
            scroll={{ x: 960 }}
            rowKey={(record) => `${record.id}`}
            dataSource={patientNoti}
            columns={columns}
            loading={loading}
          // onChange={handleTableChange}
          // pagination={{
          //   position: 'bottom',
          //   defaultPageSize: 10,
          //   showSizeChanger: true,
          //   pageSizeOptions: ['10', '25', '50', '100'],
          //   total: pagination.total,
          //   current: pagination.currentPage,
          //   showTotal: (total, range) =>
          //     `${range[0]}-${range[1]} of ${total} items`,
          // }}
          />
        </Card>
        {formDialog.open && (
          <PatientCreateNoti
            visible={formDialog.open}
            onClose={() => {
              setFormDialog({
                open: false,
                data: null,
              })
              setRefetch(true)
            }}
            data={formDialog.data}
          />
        )}
        {formDialogStatus.open && (
          <PatientStatus
            visible={formDialogStatus.open}
            onClose={() => {
              setFormDialogStatus({
                open: false,
                data: null,
              })
              setRefetch(true)
            }}
            data={formDialogStatus.data}
          />
        )}
      </Card>
      <EditModal data={editModal.data} open={editModal.open} onClose={handleCloseEdit} onSubmit={handleSubmitEdit} loading={editModal.loading} />
    </div>
  )
}

function EditModal({ data, onSubmit, onClose, open = false, loading = false }) {

  const [state, setState] = useState({
    hospitalNumber: '',
    fullname: "",
    phoneNumber: "",
  })

  function handleSubmit(d) {
    onSubmit(state)
    return true
  }

  useEffect(() => {
    setState(data)
  }, [open, data])

  return (
    <Modal
      title="ข้อมูลผู้เข้ารักษา"
      visible={open}
      onCancel={() => {
        if (!loading) { onClose() }
      }}
      footer={
        [
          <Button
            key='back'
            onClick={onClose}
            disabled={loading}
          >
            ยกเลิก
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={handleSubmit}
          >
            บันทึก
          </Button>,
        ]
      }
    >
      <Form layout='vertical' style={{ marginBottom: 8 }}>
        <Form.Item label="หมายเลข HN" required>
          <Input disabled={loading} value={state.hospitalNumber} onChange={e => { setState({ ...state, hospitalNumber: e.target.value }) }} />
        </Form.Item>
        <Form.Item label="ชื่อ" required>
          <Input disabled={loading} value={state.fullname} onChange={e => { setState({ ...state, fullname: e.target.value }) }} />
        </Form.Item>
        <Form.Item label="เบอร์มือถือ" required>
          <Input disabled={loading} value={state.phoneNumber} onChange={e => { setState({ ...state, phoneNumber: e.target.value }) }} />
        </Form.Item>
      </Form>
    </Modal >
  )
}

export default Patient
