import React from 'react'
import {
  Input,
  Form,
  Col,
  Modal,
  Button,
  Radio,
  DatePicker,
  TimePicker,
  Space,
  Avatar,
  Card,
  Typography,
} from 'antd'
import moment from 'moment'
import { useFormik } from 'formik'
import AvatarUrl from '../../Assets/Images/avatar.svg'

import { usePatient } from '../../hooks'

const { Text, Link } = Typography

export const PatientForm = ({ data, visible, onClose, edit }) => {
  const { setPatient } = usePatient()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.id || '',
      hospitalNumber: data?.hospitalNumber || '',
      fullname: data?.fullname || '',
      phoneNumber: data?.phoneNumber || '',
      description: data?.description || '',
      notiListId: data?.notiLists?.id || '',
      bookingDate: data?.notiLists?.bookingDate,
      preparationType: data?.notiLists?.preparationType || null,
    },
    onSubmit: async (values, action) => {
      action.setSubmitting(true)
      const res = await setPatient(values)
      if (res.status === 'success') {
        action.setSubmitting(false)
        onClose()
      }
    },
  })
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
    resetForm,
    isSubmitting,
  } = formik

  const scores = ['0', 'ไม่ดี(1)', 'ไม่ดี(2)', 'ดี(3)', 'ดีมาก(4)']

  function onOk(value, type) {
    if (type === 'time') {
      setFieldValue(
        'bookingDate',
        new Date(values.bookingDate).setHours(
          moment(value).format('HH'),
          moment(value).format('mm')
        )
      )
    } else if (type === 'date') {
      setFieldValue(
        'bookingDate',
        new Date(values.bookingDate).setFullYear(
          parseInt(moment(value).format('YYYY')),
          moment(value).format('MM') - 1,
          parseInt(moment(value).format('DD'))
        )
      )
    }
  }

  return (
    <Modal
      visible={visible}
      title={
        values.id ? 'ข้อมูลผู้เข้ารักษา' : 'แก้ไขบันทึกรายละเอียดผู้เข้ารักษา'
      }
      onCancel={() => {
        resetForm()
        onClose()
      }}
      onOk={handleSubmit}
      footer={
        edit
          ? [
            <Button
              key='back'
              onClick={() => {
                resetForm()
                onClose()
              }}
            >
              ยกเลิก
            </Button>,
            <Button
              key='submit'
              type='primary'
              loading={isSubmitting}
              onClick={handleSubmit}
            >
              บันทึก
            </Button>,
          ]
          : null
      }
    >
      <Col>
        <Form layout='vertical' style={{ marginBottom: 8 }}>
          <Form.Item
            style={{
              marginBottom: 0,
            }}
          >
            <Card bordered={false}>
              <Card.Grid
                style={{
                  width: '35%',
                  textAlign: 'center',
                  border: 0,
                  boxShadow: 'none',
                }}
                hoverable={false}
              >
                <Avatar
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  src={AvatarUrl}
                />
              </Card.Grid>
              <Card.Grid
                style={{
                  width: '65%',
                  textAlign: 'left',
                  border: 0,
                  boxShadow: 'none',
                }}
                hoverable={false}
              >
                <Space direction='vertical'>
                  <Link target='_blank'>{data?.hospitalNumber}</Link>
                  <span>
                    <Text strong>ชื่อ </Text>
                    <Text>{data?.fullname}</Text>
                  </span>
                  <span>
                    <Text strong>เบอร์มือถือ </Text>
                    <Text t>{data?.phoneNumber}</Text>
                  </span>
                </Space>
              </Card.Grid>
            </Card>
          </Form.Item>

          {edit ? (
            <>
              <Form.Item
                style={{
                  marginBottom: 0,
                }}
                label='วันและเวลานัดส่องกล้อง'
              >
                <Form.Item
                  help={touched.bookingDate && errors.bookingDate}
                  validateStatus={
                    touched.bookingDate && errors.bookingDate
                      ? 'error'
                      : undefined
                  }
                  style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    format='DD-MM-YYYY'
                    value={
                      values.bookingDate && values.bookingDate
                        ? moment(values.bookingDate)
                        : undefined
                    }
                    onChange={(value) => onOk(value, 'date')}
                  />
                </Form.Item>
                <Form.Item
                  help={touched.bookingDate && errors.bookingDate}
                  validateStatus={
                    touched.bookingDate && errors.bookingDate
                      ? 'error'
                      : undefined
                  }
                  style={{
                    display: 'inline-block',
                    width: 'calc(50%-12)',
                    marginLeft: 12,
                  }}
                >
                  <TimePicker
                    style={{ width: '100%' }}
                    format='HH:mm'
                    value={
                      values.bookingDate && values.bookingDate
                        ? moment(values.bookingDate)
                        : undefined
                    }
                    onOk={(value) => onOk(value, 'time')}
                    minuteStep={15}
                  />
                </Form.Item>
              </Form.Item>

              <Form.Item
                label='รูปแบบรับประทานยา'
                help={touched.preparationType && errors.preparationType}
                validateStatus={
                  touched.preparationType && errors.preparationType
                    ? 'error'
                    : undefined
                }
              >
                <Radio.Group
                  onChange={handleChange}
                  value={values.preparationType}
                  name='preparationType'
                  onBlur={handleBlur}
                  disabled={true}
                >
                  <Radio value={1}>Split dose patient</Radio>
                  <Radio value={2}>Day-before patient</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label='รายละเอียด'
                help={touched.description && errors.description}
                validateStatus={
                  touched.description && errors.description
                    ? 'error'
                    : undefined
                }
              >
                <Input.TextArea
                  id='description'
                  name='description'
                  placeholder=''
                  value={values.description}
                  onChange={(e) => setFieldValue('description', e.target.value)}
                  onBlur={handleBlur}
                  autoSize={{ minRows: 4, maxRows: 10 }}
                />
              </Form.Item>
            </>
          ) : (
            <>
              <Space direction='vertical'>
                <span>
                  <Text strong>วันและเวลานัดส่องกล้อง : </Text>
                  <Text>
                    {moment(values?.bookingDate).format('DD-MM-YYYY hh:mm น.')}
                  </Text>
                </span>
                <span>
                  <Text strong>การเตรียมตัว : </Text>
                  <Text>
                    {values?.preparationType === 1
                      ? 'Split dose patient'
                      : 'Day-before patient'}
                  </Text>
                </span>
                <span>
                  <Text strong>รายละเอียด : </Text>
                  <Text>{values?.description} </Text>
                </span>
                <div style={{ overflowY: 'auto', height: 450 }}>
                  {data?.results?.length > 0 &&
                    data?.results.map((item) => (
                      <Text>
                        <Text strong>รูปภาพ</Text>
                        <Card
                          style={{
                            marginTop: 10,
                          }}
                          bordered={false}
                          hoverable
                          cover={
                            <img
                              alt={item?.resultImage}
                              src={item?.resultImage}
                            />
                          }
                        >
                          <Card.Meta
                            style={{ textAlign: 'center' }}
                            title={
                              <>
                                <p>คะแนนการประเมิน {item ? scores[item.score] : ''}</p>
                                <Link target='_blank'>
                                  {moment(item?.createdAt).format('DD/MM/YYYY')}
                                </Link>
                              </>
                            }
                          />
                        </Card>
                      </Text>
                    ))}
                </div>
              </Space>
            </>
          )}
        </Form>
      </Col>
    </Modal>
  )
}

export default PatientForm
