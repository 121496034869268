import React from 'react'
import { Form, Col, Modal, Button, DatePicker, TimePicker } from 'antd'
import moment from 'moment'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { usePatient } from '../../hooks'

const validationSchema = () => {
  return yup.object().shape({
    notifyTime: yup.string().required('กรุณาระบุวันและเวลาแจ้งเตือน'),
  })
}

export const PatientForm = ({ data, visible, onClose, edit }) => {
  const { setPatientNotiTime } = usePatient()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      patientId: data?.id || '',
      notifyTime: undefined,
    },
    validationSchema,
    onSubmit: async (values, action) => {
      action.setSubmitting(true)
      const res = await setPatientNotiTime(values)
      if (res.status === 'success') {
        action.setSubmitting(false)
        onClose()
      }
    },
  })
  const {
    handleSubmit,
    errors,
    touched,
    values,
    setFieldValue,
    resetForm,
    isSubmitting,
  } = formik

  function onOk(value, type) {
    if (type === 'time') {
      setFieldValue(
        'notifyTime',
        new Date(values.notifyTime).setHours(
          moment(value).format('HH'),
          moment(value).format('mm')
        )
      )
    } else if (type === 'date') {
      setFieldValue(
        'notifyTime',
        new Date(values.notifyTime).setFullYear(
          parseInt(moment(value).format('YYYY')),
          moment(value).format('MM') - 1,
          parseInt(moment(value).format('DD'))
        )
      )
    }
  }
  console.log(data)
  return (
    <Modal
      visible={visible}
      title={'สร้างรายการแจ้งเตือนส่งภาพอุจจาระ'}
      onCancel={() => {
        resetForm()
        onClose()
      }}
      onOk={handleSubmit}
      footer={[
        <Button
          key='back'
          onClick={() => {
            resetForm()
            onClose()
          }}
        >
          ยกเลิก
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          บันทึก
        </Button>,
      ]}
    >
      <Col>
        <Form layout='vertical' style={{ marginBottom: 8 }}>
          <Form.Item
            style={{
              marginBottom: 0,
            }}
            label='ระบุวันและเวลาแจ้งเตือน'
          >
            <Form.Item
              help={touched.notifyTime && errors.notifyTime}
              validateStatus={
                touched.notifyTime && errors.notifyTime ? 'error' : undefined
              }
              style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
            >
              <DatePicker
                placeholder='เลือกวัน'
                style={{ width: '100%' }}
                format='DD-MM-YYYY'
                value={
                  values.notifyTime && values.notifyTime
                    ? moment(values.notifyTime)
                    : undefined
                }
                onChange={(value) => onOk(value, 'date')}
              />
            </Form.Item>
            <Form.Item
              help={touched.notifyTime && errors.notifyTime}
              validateStatus={
                touched.notifyTime && errors.notifyTime ? 'error' : undefined
              }
              style={{
                display: 'inline-block',
                width: 'calc(50%-12)',
                marginLeft: 12,
              }}
            >
              <TimePicker
                placeholder='เลือกเวลา'
                style={{ width: '100%' }}
                format='HH:mm'
                value={
                  values.notifyTime && values.notifyTime
                    ? moment(values.notifyTime)
                    : undefined
                }
                onOk={(value) => onOk(value, 'time')}
                minuteStep={15}
              />
            </Form.Item>
          </Form.Item>
        </Form>
      </Col>
    </Modal>
  )
}

export default PatientForm
