import React from 'react'
import { Bar } from 'react-chartjs-2'
import moment from 'moment'

const ChartBar = ({ dashboardChart }) => {
  const data = {
    labels: dashboardChart
      ?.sort((a, b) => new Date(a.date) - new Date(b.date))
      .map((chart) => moment(chart.date).format('DD-MM-YYYY')),
    datasets: [
      {
        label: 'จำนวนผู้เข้ารักษา',
        data: dashboardChart
          ?.sort((a, b) => new Date(a.date) - new Date(b.date))
          .map((chart) => chart.total),
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgba(54, 162, 235, 0.5)',
        borderWidth: 1,
      },
    ],
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }

  return <Bar data={data} options={options} height={150} />
}

export default ChartBar
