import React, { useEffect } from 'react'
import { message } from 'antd'
import { useAppContext } from '../../context'

export default function Notify({ notify }) {
  const { setMessage } = useAppContext()

  useEffect(() => {
    if (notify) {
      onNotify(notify)
      setMessage(null)
    }
    // eslint-disable-next-line
  }, [notify])

  const [messsageApi, contextHolder] = message.useMessage()
  const onNotify = (notify) => {
    messsageApi.open({
      type: notify?.variant,
      content: notify?.message,
      duration: 1,
    })
  }

  return <>{contextHolder}</>
}
