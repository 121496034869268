import React, { useState } from 'react'
import { Input, Form, Col, Modal, Space, Button, Upload, Image } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useFormik } from 'formik'
import styled from 'styled-components'

import { useDocument } from '../../hooks'
import config from '../../config.json'

const CustomUpload = styled(Upload)``

export const DocumentForm = ({ data, visible, onClose, loading }) => {
  const { setDocument } = useDocument()
  const [uploading, setUpLoading] = useState(false)

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      id: data?.id || '',
      name: data?.name || '',
      description: data?.description || '',
      cover: data?.cover || '',
      url: data?.url || '',
    },
    validate: (values) => {
      let errors = {}

      if (values?.name === '' || !values?.name) {
        errors.name = 'กรุณาใส่ชื่อรายการ'
      }

      if (values?.url === '' || !values?.url) {
        errors.url = 'กรุณาใส่ลิงค์เชื่อมโยง'
      }

      if (values?.description === '' || !values?.description) {
        errors.description = 'กรุณาใส่รายละเอียด'
      }

      return errors
    },
    onSubmit: async (values, action) => {
      let url = values.cover.split('/')
      const cover = url[url.length - 1]

      const res = await setDocument({ ...values, cover })
      action.setSubmitting(true)

      if (res.status === 'success') {
        action.setSubmitting(false)

        onClose()
      }
    },
  })

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
    resetForm,
    isSubmitting,
  } = formik

  const handleUploadChange = (info) => {
    if (info.file.status === 'uploading') {
      setUpLoading(true)
      setFieldValue('cover', '')
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      setFieldValue('cover', info.file.response.url)
      setUpLoading(false)
    }
  }

  return (
    <Modal
      visible={visible}
      title={values.id ? 'แก้ไขรายการ' : 'สร้างรายการ'}
      onCancel={() => {
        resetForm()
        onClose()
      }}
      onOk={handleSubmit}
      footer={[
        <Button
          key='back'
          onClick={() => {
            resetForm()
            onClose()
          }}
        >
          ยกเลิก
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={isSubmitting || uploading}
          onClick={handleSubmit}
        >
          บันทึก
        </Button>,
      ]}
    >
      <Col>
        <Form layout='vertical' style={{ marginBottom: 8 }}>
          <Form.Item
            label='ชื่อรายการ'
            help={touched.name && errors.name}
            validateStatus={touched.name && errors.name ? 'error' : undefined}
          >
            <Input
              name='name'
              placeholder=''
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Item>
        </Form>
        <Form layout='horizontal' autoComplete='off'>
          <Form.Item
            label='ลิงค์เชื่อมโยง'
            help={touched.url && errors.url}
            validateStatus={touched.url && errors.url ? 'error' : undefined}
          >
            <Input
              name='url'
              placeholder='https://'
              value={values.url}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Item>
        </Form>
        <Form.Item>
          <CustomUpload
            name='file'
            listType='text'
            showUploadList={false}
            action={config.uploadUrl}
            onChange={handleUploadChange}
            data={{
              name: `doc${Date.now() + Math.round(Math.random() * 1e9)}`,
            }}
          >
            {values?.cover !== '' ? (
              <Image src={values?.cover} preview={false} />
            ) : (
              <Space>
                <Button icon={<UploadOutlined />} loading={uploading}>
                  Upload
                </Button>
                แนบรูปภาพประกอบ
              </Space>
            )}
          </CustomUpload>
        </Form.Item>
        <Form layout='vertical' style={{ marginBottom: 8 }}>
          <Form.Item
            label='รายละเอียด'
            help={touched.description && errors.description}
            validateStatus={
              touched.description && errors.description ? 'error' : undefined
            }
          >
            <Input.TextArea
              name='description'
              placeholder=''
              value={values.description}
              onChange={(e) => setFieldValue('description', e.target.value)}
              onBlur={handleBlur}
              autoSize={{ minRows: 6, maxRows: 10 }}
            />
          </Form.Item>
        </Form>
      </Col>
    </Modal>
  )
}

export default DocumentForm
