import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Layout, Form, Input, Button, Card, message } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useAuth } from '../../hooks'

import LogoImage from '../../Assets/Images/logo.svg'
import Avatar from 'antd/lib/avatar/avatar'

const LoginCard = styled(Card)`
  margin-top: 20vh;
  overflow: initial;
  justify-content: center;
  align-self: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  width: 485px;
  height: 506px;
  padding: 26px;
`

const FormTitle = styled.h2`
  justify-content: center;
  text-align: center;
  margin: 1rem;
  color: #efc342;
  font-size: 15px;
`

function LoginScreen() {
  const { login, loading, error } = useAuth()

  const onFinish = (data) => {
    login(data.username, data.password)
  }

  useEffect(() => {
    if (loading) {
      message.loading({
        content: 'กรุณารอสักครู่',
        key: 'Login',
      })
    }
  }, [loading])

  useEffect(() => {
    if (error) {
      message.error({
        content: error?.message,
        key: 'Login',
        duration: 5,
      })
    }
  }, [error])

  return (
    <Layout
      style={{
        height: '100vh',
        width: '100%',
        background: 'linear-gradient(180deg, #254685 0%, #1E3755 100%)',
      }}
    >
      <LoginCard>
        <Form onFinish={onFinish} style={{ textAlign: 'center' }}>
          <Avatar
            shape='square'
            src={LogoImage}
            style={{ width: 92, height: 108 }}
          />
          <FormTitle>
            เว็บการจัดการข้อมูลการติดต่อสื่อสารกับผู้เข้ารับการรักษา
          </FormTitle>

          <Form.Item
            name='username'
            rules={[
              {
                required: true,
                message: 'กรุณา ระบุชื่อผู้ใช้',
              },
            ]}
            style={{ width: '100%' }}
          >
            <Input
              prefix={<UserOutlined className='site-form-item-icon' />}
              placeholder='ชื่อผู้ใช้'
            />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: 'กรุณา ระบุรหัสผ่าน',
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className='site-form-item-icon' />}
              type='password'
              placeholder='รหัสผ่าน'
            />
          </Form.Item>
          <br />
          <Form.Item>
            <Button type='primary' htmlType='submit' block>
              เข้าสู่ระบบ
            </Button>
          </Form.Item>
        </Form>
      </LoginCard>
    </Layout>
  )
}

export default LoginScreen
