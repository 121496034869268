import { useState, useEffect } from 'react'

import firebase from '../config/firebase'

export function useAuth() {
  const [userInfo, setUser] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!userInfo) {
      getLogin()
    }
    // eslint-disable-next-line
  }, [userInfo])

  function login(email, password) {
    setLoading(true)
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(function (result) {
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        setError(error)
      })
  }

  function logout() {
    firebase.auth().signOut()
  }

  function getLogin() {
    firebase
      .app()
      .functions('asia-southeast2')
      .httpsCallable('getLogin')()
      .then((res) => {
        setUser(res?.data)
      })
      .catch((error) => ({ error }))
  }
  return {
    userInfo,
    loading,
    login,
    logout,
    error,
    getLogin,
  }
}
