import { useState, useEffect } from 'react'
import firebase from '../config/firebase'

import { useAppContext } from '../context'

export function useDashboard(id) {
  const { setError, setMessage } = useAppContext()
  const [loading, setLoading] = useState(false)
  const [dashboardTotal, setDashboardTotal] = useState()
  const [dashboardChart, setDashboardChart] = useState()
  const [dashboardContact, setDashboardContact] = useState()
  const [dashboardAssessment, setDashboardAssessment] = useState()

  useEffect(() => {
    getDashboardTotal()
    getDashboardChart()
    getDashboardContact()
    getAssessment()
    // eslint-disable-next-line
  }, [])

  async function getDashboardTotal() {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('dashboardTotal')()
        .catch((error) => ({ error }))

      if (res.data) {
        setDashboardTotal(res.data)
        setLoading(false)

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Get Dashboards Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Get Dashboards Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function getDashboardChart() {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('dashboardChart')()
        .catch((error) => ({ error }))

      if (res.data) {
        setDashboardChart(res.data)
        setLoading(false)

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Get Dashboards Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Get Dashboards Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function getDashboardContact() {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('dashboardContact')()
        .catch((error) => ({ error }))

      if (res.data) {
        setDashboardContact(res.data)
        setLoading(false)

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Get Dashboards Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Get Dashboards Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function setDashboardContactData(data) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('dashboardSetContact')(data)
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)

        setMessage({
          variant: 'success',
          message: 'Success',
        })

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Set Dashboard Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Set Dashboard Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function getAssessment() {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('dashboardAssessment')()
        .catch((error) => ({ error }))

      if (res.data) {
        setDashboardAssessment(res.data)
        setLoading(false)

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Get Dashboards Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Get Dashboards Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  return {
    loading,
    getDashboardTotal,
    getDashboardContact,
    getDashboardChart,
    setDashboardContactData,
    dashboardTotal,
    dashboardChart,
    dashboardContact,
    dashboardAssessment,
  }
}
