import { useState, useEffect } from 'react'
import firebase from '../config/firebase'

import { useAppContext } from '../context'

export function useRegimen(id) {
  const { setError, setMessage } = useAppContext()
  const [loading, setLoading] = useState(false)
  const [regimenList, setRegimenList] = useState([])
  const [regimenData, setRegimenData] = useState()

  const [pagination, setPagination] = useState({
    page: 0,
    page_total: 10,
    total: 10,
    currentPage: 1,
    search: '',
    filter: {
      preparationType: '',
      medicalGroupId: '',
    },
  })

  useEffect(() => {
    id && getRegimenById(id)
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    getRegimens()
    // eslint-disable-next-line
  }, [])

  async function getRegimens(
    page = 0,
    page_total = 10,
    search = '',
    filter = {
      preparationType: '',
      medicalGroupId: '',
    }
  ) {
    setLoading(true)
    let data = { page, page_total, search, filter }

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('medicationList')(data)
        .catch((error) => ({ error }))

      if (res.data) {
        setRegimenList(res.data.list)
        setPagination({
          page: page,
          page_total: page_total,
          total: res.data.total,
          currentPage: page + 1,
          search: search,
          filter: filter,
        })

        setLoading(false)

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Get Regimen Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Get Regimen Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function setRegimen(data) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('setMedication')(data)
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)

        setMessage({
          variant: 'success',
          message: 'Success',
        })
        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Set Regimen Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Set Regimen Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function deleteRegimen(id) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('deleteMedication')({ id })
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)

        setMessage({
          variant: 'success',
          message: 'Success',
        })
        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Delete Regimen Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Delete Regimen Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function setRegimenUsed(data) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('statusMedication')(data)
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)

        setMessage({
          variant: 'success',
          message: 'Success',
        })
        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Set Used Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Set Used Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function getRegimenById(id) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('medicationDetail')({ id })
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)
        setRegimenData(res.data)

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Set Regimen Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Set Regimen Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  function handleTableChange(paginationChange) {
    const { search, filter } = pagination

    getRegimens(
      paginationChange.current - 1,
      paginationChange.pageSize,
      search,
      filter
    )
  }

  function handelCustomSearch(text) {
    const { page, page_total, filter } = pagination
    getRegimens(page, page_total, text, filter)
  }

  function handelFilter(value) {
    const { page, page_total, search } = pagination

    const id = value.split('-')

    let filter = {
      preparationType: '',
      medicalGroupId: '',
    }

    if (value !== '') {
      filter = {
        preparationType: id[0],
        medicalGroupId: id[1],
      }
    }

    getRegimens(page, page_total, search, filter)
  }

  return {
    loading,
    getRegimens,
    regimenList,
    pagination,
    handleTableChange,
    handelCustomSearch,
    setRegimen,
    regimenData,
    deleteRegimen,
    setRegimenUsed,
    getRegimenById,
    handelFilter,
  }
}
