import { useState, useEffect } from 'react'
import firebase from '../config/firebase'

import { useAppContext } from '../context'

export function useDocument(id) {
  const { setError, setMessage } = useAppContext()
  const [loading, setLoading] = useState(false)
  const [documentList, setDocumentList] = useState([])
  const [documentData, setDocumentData] = useState()

  const [pagination, setPagination] = useState({
    page: 0,
    page_total: 10,
    total: 10,
    currentPage: 1,
    search: '',
  })

  useEffect(() => {
    id && getDocumentById(id)
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    getDocuments()
    // eslint-disable-next-line
  }, [])

  async function getDocuments(page = 0, page_total = 10, search = '') {
    setLoading(true)
    let data = { page, page_total, search }

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('documentList')(data)
        .catch((error) => ({ error }))

      if (res.data) {
        setDocumentList(res.data.list)
        setPagination({
          page: page,
          page_total: page_total,
          total: res.data.total,
          currentPage: page + 1,
          search: search,
        })

        setLoading(false)

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Get Document Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Get Document Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function setDocument(data) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('setDocument')(data)
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)

        setMessage({
          variant: 'success',
          message: 'Success',
        })
        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Set Document Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Set Document Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function deleteDocument(id) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('deleteDocument')({ id })
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)

        setMessage({
          variant: 'success',
          message: 'Success',
        })
        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Delete Document Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Delete Document Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function setDocumentUsed(data) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('statusDocument')(data)
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)

        setMessage({
          variant: 'success',
          message: 'Success',
        })
        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Set Used Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Set Used Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  async function getDocumentById(id) {
    setLoading(true)

    try {
      const res = await firebase
        .app()
        .functions('asia-southeast2')
        .httpsCallable('documentDetail')({ id })
        .catch((error) => ({ error }))

      if (res.data) {
        setLoading(false)
        setDocumentData(res.data)

        return {
          status: 'success',
        }
      } else {
        setError(res)
        setMessage({
          variant: 'error',
          message: res?.error?.message || 'Set Document Error',
        })
        setLoading(false)

        return {
          status: 'error',
        }
      }
    } catch (error) {
      setError(error)
      setMessage({
        variant: 'error',
        message: 'Set Document Error',
      })
      setLoading(false)

      return {
        status: 'error',
      }
    }
  }

  function handleTableChange(paginationChange, filters, sorter) {
    const { search } = pagination
    getDocuments(
      paginationChange.current - 1,
      paginationChange.pageSize,
      search
    )
  }

  function handelCustomSearch(text) {
    const { page, page_total } = pagination
    getDocuments(page, page_total, text)
  }

  return {
    loading,
    getDocuments,
    documentList,
    pagination,
    handleTableChange,
    handelCustomSearch,
    setDocument,
    documentData,
    deleteDocument,
    setDocumentUsed,
    getDocumentById,
  }
}
