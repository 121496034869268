import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Card, Table, Breadcrumb, Button, Input } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import moment from 'moment'
import { usePatient } from '../../hooks'

import PatientEditForm from './PatientEditForm'

export const Patient = () => {
  const {
    loading,
    patientList,
    pagination,
    handleTableChange,
    handelCustomSearch,
    getPatients,
  } = usePatient()

  const [refetch, setRefetch] = useState(false)

  const [editDialog, setEditDialog] = useState({
    open: false,
    data: null,
    edit: false,
  })

  useEffect(() => {
    if (refetch) {
      const { page, page_total, search, filter } = pagination
      getPatients(page, page_total, search, filter)
    }
    setRefetch(false)
    // eslint-disable-next-line
  }, [refetch])

  const columns = [
    // {
    //   title: 'No.',
    //   dataIndex: 'no.',
    //   width: 50,
    //   key: 'no.',
    //   align: 'center',
    //   render: (data, row, index) => {
    //     return pagination.page * pagination.page_total + index + 1
    //   },
    // },
    {
      title: 'HN',
      dataIndex: 'hospitalNumber',
      align: 'left',
      key: 'hospitalNumber',
      render: (item, record) => (
        <Link
          style={{ padding: '0px !important' }}
          type='link'
          to={`/patients/noti/${record.id}`}
        >
          {item}
        </Link>
      ),
    },
    {
      title: 'ชื่อ',
      dataIndex: 'fullname',
      align: 'left',
      key: 'fullname',
      render: (item, record) => (
        <Link
          style={{ padding: '0px !important' }}
          type='link'
          to={`/patients/noti/${record.id}`}
        >
          {item}
        </Link>
      ),
    },
    {
      title: 'เบอร์โทรศัทท์มือถือ',
      dataIndex: 'phoneNumber',
      align: 'left',
      key: 'phoneNumber',
    },
    {
      title: 'การเตรียมตัว',
      dataIndex: 'notiLists',
      align: 'center',
      width: '100px',
      key: 'notiLists',
      render: (item, record) =>
        item?.preparationType === 1
          ? 'Spit dose regimen'
          : item?.preparationType === 2
            ? 'Day-before regimen'
            : '',
    },
    // {
    //   title: 'บันทึกรายละเอียด',
    //   dataIndex: 'description',
    //   align: 'center',
    //   key: 'description',
    // },
    {
      title: 'รายการรับประทานยา',
      dataIndex: 'notiLists',
      align: 'left',
      width: '200px',
      key: 'notiLists',
      render: (item) =>
        item?.tasks?.length > 0 ? item?.tasks.map(t => t.medication.name).join(`,\n`) : ''
    },
    {
      title: 'วันนัดส่องกล้อง',
      dataIndex: 'notiLists',
      align: 'center',
      key: 'bookingDate',
      width: '100px',
      render: (item, record) =>
        item?.bookingDate &&
        moment(new Date(item?.bookingDate)).format('DD-MM-YYYY') + `\n` + moment(new Date(item?.bookingDate)).format('HH:mm') + ' น.',
    },
    // {
    //   title: 'เวลา',
    //   dataIndex: 'notiLists',
    //   align: 'center',
    //   key: 'bookingTime',
    //   render: (item, record) =>
    //     item?.bookingDate &&
    //     moment(new Date(item?.bookingDate)).format('HH:mm') + ' น.',
    // },

    {
      title: 'แก้ไข',
      dataIndex: 'edit',
      align: 'center',
      render: (item, record) =>
        record.status !== null && (
          <Button
            type='link'
            icon={<EditOutlined />}
            onClick={() => {
              setEditDialog({
                open: true,
                data: record,
                edit: true,
              })
            }}
          />
        ),
    },
    {
      title: 'แจ้งเตือน',
      dataIndex: 'noti',
      align: 'center',
      key: 'noti',
    },
    {
      title: 'รูปภาพ',
      dataIndex: 'results',
      align: 'left',
      key: 'results',
      render: (item, record) => (
        <Link
          to='#'
          onClick={() => {
            setEditDialog({
              open: true,
              data: record,
            })
          }}
        >
          {item.length}
        </Link>
      ),
    },
    {
      title: 'สภานะ',
      dataIndex: 'status',
      align: 'center',
      key: 'status',
      render: (item, record) =>
        item === 0 ? 'เสร็จสิ้น' : item === 1 ? 'ดำเนินการ' : '',
    },
  ]

  return (
    <div>
      <Breadcrumb style={{ marginBottom: 16 }}>
        <Breadcrumb.Item>
          <Link to='/patients'>ข้อมูลผู้เข้ารักษา</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card
        title={
          <Input.Search
            placeholder='พิมพ์เพื่อค้นหา'
            onSearch={(text) => handelCustomSearch(text)}
            style={{ width: 320, marginRight: 10 }}
          />
        }
      >
        <Table
          size='small'
          rowClassName={(record, index) =>
            index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
          }
          scroll={{ x: 960 }}
          rowKey={(record) => `${record.id}`}
          dataSource={patientList}
          columns={columns}
          loading={loading}
          onChange={handleTableChange}
          pagination={{
            position: 'bottom',
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50', '100'],
            total: pagination.total,
            current: pagination.currentPage,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
        {editDialog.open && (
          <PatientEditForm
            visible={editDialog.open}
            onClose={() => {
              setEditDialog({
                open: false,
                data: null,
                edit: false,
              })
              setRefetch(true)
            }}
            data={editDialog.data}
            edit={editDialog.edit}
          />
        )}
      </Card>
    </div>
  )
}

export default Patient
