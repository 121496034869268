import React, { useState, useEffect } from 'react'
import {
  Input,
  Form,
  Col,
  Modal,
  Button,
  Radio,
  Select,
  TimePicker,
  InputNumber,
  Upload,
  Space,
  Image,
} from 'antd'
import moment from 'moment'
import { UploadOutlined } from '@ant-design/icons'
import * as yup from 'yup'
import { useFormik } from 'formik'

import { useRegimen, useGroup } from '../../../hooks'
import config from '../../../config.json'

const { RangePicker } = TimePicker

const validationSchema = () => {
  return yup.object().shape({
    name: yup.string().required('กรุณาใส่ชื่อรายการ'),
    preparationType: yup.string().required('กรุณาเลือกรูปแบบการรับประทานยา'),
    startTime: yup.string().required('กรุณาเลือกช่วงเวลารับประทานยา'),
    endTime: yup.string().required('กรุณาเลือกช่วงเวลารับประทานยา'),
    img: '',
    medicalId: yup.string().required('กรุณาเลือกชื่อยา'),
    medicalAmount: yup.number().required('กรุณาใส่จำนวนซอง'),
    waterAmount: yup.string().required('กรุณาใส่ปริมาณผสมน้ำ'),
    waterUnit: yup.string().required('กรุณาเลือกหน่วย'),
  })
}

export const RegimenForm = ({ data, visible, onClose }) => {
  const { setRegimen } = useRegimen()
  const {
    groupList,
    getGroupById,
    groupData,
    loading: groupLoading,
  } = useGroup()

  const [uploading, setUpLoading] = useState(false)

  useEffect(() => {
    if (data?.medical?.medicalGroupId) {
      getGroupById(data?.medical?.medicalGroupId)
    }
    // eslint-disable-next-line
  }, [data])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.id || '',
      name: data?.name || '',
      preparationType: data?.preparationType || '',
      startTime: data?.startTime || undefined,
      endTime: data?.endTime || undefined,
      img: data?.img || '',
      medicalId: data?.medical?.id || '',
      medicalAmount: data?.medicalAmount || undefined,
      waterAmount: data?.waterAmount || '',
      waterUnit: data?.waterUnit || '',
      description: data?.description || '',
    },
    validationSchema,
    onSubmit: async (values, action) => {
      action.setSubmitting(true)
      let url = values.img.split('/')
      const img = url[url.length - 1]

      const res = await setRegimen({ ...values, img })
      if (res.status === 'success') {
        action.setSubmitting(false)

        onClose()
      }
    },
  })

  const handleUploadChange = (info) => {
    if (info.file.status === 'uploading') {
      setUpLoading(true)
      setFieldValue('img', '')
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      setFieldValue('img', info.file.response.url)
      setUpLoading(false)
    }
  }
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
    resetForm,
    isSubmitting,
  } = formik

  function onOk(value) {
    value[0] && setFieldValue('startTime', moment(value[0]).format('HH:mm'))
    value[1] && setFieldValue('endTime', moment(value[1]).format('HH:mm'))
  }
  return (
    <Modal
      visible={visible}
      title={values.id ? 'แก้ไขรายการรับประทานยา' : 'สร้างรายการรับประทานยา'}
      onCancel={() => {
        resetForm()
        onClose()
      }}
      onOk={handleSubmit}
      footer={[
        <Button
          key='back'
          onClick={() => {
            resetForm()
            onClose()
          }}
        >
          ยกเลิก
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={isSubmitting || uploading}
          onClick={handleSubmit}
        >
          บันทึก
        </Button>,
      ]}
    >
      <Col>
        <Form layout='vertical' style={{ marginBottom: 8 }}>
          <Form.Item
            label='ชื่อรายการ'
            help={touched.name && errors.name}
            validateStatus={touched.name && errors.name ? 'error' : undefined}
          >
            <Input
              name='name'
              placeholder=''
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Item>
          <Form.Item
            label='รูปแบบรับประทานยา'
            help={touched.preparationType && errors.preparationType}
            validateStatus={
              touched.preparationType && errors.preparationType
                ? 'error'
                : undefined
            }
          >
            <Radio.Group
              onChange={handleChange}
              value={values.preparationType}
              name='preparationType'
              onBlur={handleBlur}
            >
              <Radio value={1}>Split dose regimen</Radio>
              <Radio value={2}>Day-before regimen</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label='หมวดหมู่ยา'>
            <Select
              name='medicalGrop'
              placeholder='เลือกหมวดหมูยา'
              onChange={(value) => {
                getGroupById(value)
                setFieldValue('medicalId', '')
              }}
              defaultValue={data?.medical?.medicalGroupId}
            >
              {groupList?.length > 0 &&
                groupList.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              style={{ display: 'inline-block', width: 'calc(30% - 12px)' }}
              label='ชื่อยา'
              help={touched.medicalId && errors.medicalId}
              validateStatus={
                touched.medicalId && errors.medicalId ? 'error' : undefined
              }
            >
              <Select
                name='medicalId'
                placeholder='เลือกหมวดหมูยา'
                value={values.medicalId}
                onChange={(value) => setFieldValue('medicalId', value)}
                onBlur={handleBlur}
                loading={groupLoading}
              >
                {groupData &&
                  groupData?.medicals?.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              style={{
                display: 'inline-block',
                width: 'calc(20% - 12px)',
                marginLeft: 12,
              }}
              label='ซอง'
              help={touched.medicalAmount && errors.medicalAmount}
              validateStatus={
                touched.medicalAmount && errors.medicalAmount
                  ? 'error'
                  : undefined
              }
            >
              <InputNumber
                name='medicalAmount'
                placeholder=''
                min={0}
                value={values.medicalAmount}
                onChange={(value) => setFieldValue('medicalAmount', value)}
                onBlur={handleBlur}
              />
            </Form.Item>

            <Form.Item
              style={{
                display: 'inline-block',
                width: 'calc(30% - 12px)',
                marginLeft: 22,
              }}
              label='ผสมน้ำปริมาณ'
              help={touched.waterAmount && errors.waterAmount}
              validateStatus={
                touched.waterAmount && errors.waterAmount ? 'error' : undefined
              }
            >
              <Input
                id='waterAmount'
                name='waterAmount'
                value={values.waterAmount}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>

            <Form.Item
              style={{
                display: 'inline-block',
                width: 'calc(20% - 12px)',
                marginLeft: 12,
              }}
              label='หน่วย'
              help={touched.waterUnit && errors.waterUnit}
              validateStatus={
                touched.waterUnit && errors.waterUnit ? 'error' : undefined
              }
            >
              <Select
                name='waterUnit'
                placeholder=''
                value={values.waterUnit}
                onChange={(value) => setFieldValue('waterUnit', value)}
                onBlur={handleBlur}
              >
                <Select.Option value='ลิตร'>ลิตร</Select.Option>
                <Select.Option value='มิลลิลิตร'>มิลลิลิตร</Select.Option>
              </Select>
            </Form.Item>
          </Form.Item>
          <Form.Item
            label='ช่วงเวลา'
            help={
              (touched.startTime && errors.startTime) ||
              (touched.endTime && errors.endTime)
            }
            validateStatus={
              touched.startTime && errors.startTime
                ? 'error'
                : undefined || (touched.endTime && errors.endTime)
                  ? 'error'
                  : undefined
            }
          >
            <RangePicker
              showTime={{ format: 'HH:mm' }}
              format='HH:mm'
              // onChange={onChange}
              value={
                values.startTime && values.endTime
                  ? [
                    moment(values.startTime, 'HH:mm'),
                    moment(values.endTime, 'HH:mm'),
                  ]
                  : undefined
              }
              minuteStep={15}
              onOk={onOk}
            />
          </Form.Item>
          <Form.Item>
            <Upload
              name='file'
              listType='text'
              showUploadList={false}
              action={config.uploadUrl}
              onChange={handleUploadChange}
              data={{
                name: `medication${Date.now() + Math.round(Math.random() * 1e9)}`,
              }}
            >
              {values?.img !== '' ? (
                <Image src={values?.img} preview={false} />
              ) : (
                <Space>
                  <Button icon={<UploadOutlined />} loading={uploading}>
                    Upload
                  </Button>
                  แนบรูปภาพประกอบ
                </Space>
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            label='หมายเหตุ'
            help={touched.description && errors.description}
            validateStatus={
              touched.description && errors.description ? 'error' : undefined
            }
          >
            <Input.TextArea
              id='description'
              name='description'
              placeholder=''
              value={values.description}
              onChange={(e) => setFieldValue('description', e.target.value)}
              onBlur={handleBlur}
            />
          </Form.Item>
        </Form>
      </Col>
    </Modal>
  )
}

export default RegimenForm
